<!--我的订单-->
<template>
  <div class="page-order">

    <Breadcrumb :data="[{name:'My Order',}]"></Breadcrumb>

<!--    <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>-->

    <div class="common-gap"></div>

    <div class="bottom-content">

      <div class="common-card-box tab-content">
        <el-tabs class="tab" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="All Order" name="ALL"></el-tab-pane>
          <el-tab-pane label="Contacting" name="NOT_CONTACT"></el-tab-pane>
          <el-tab-pane label="Processing" name="CONTACTING"></el-tab-pane>
          <el-tab-pane label="Completed" name="COMPLETED"></el-tab-pane>
          <el-tab-pane label="Canceled Order" name="CANCELED"></el-tab-pane>
        </el-tabs>
      </div>

<!--      // 订单流程：Execution-->
<!--      // 订单三种状态翻译：-->
<!--      // 未沟通：Contacting-->
<!--      // 已沟通：Processing-->
<!--      // 完成：Complete-->

      <table class="cart-table">
        <thead>
        <tr>
          <th class="title1">Order Detail</th>
          <th>User</th>
          <th style="width: 200px">Status</th>
          <th style="width: 300px">Options</th>
        </tr>
        </thead>
        <tbody class="tbody">
        <tr :key="index + '_' + order.orderNo" class="tr" v-for="(order,index) in orderList">
          <td class="td" style="text-align: left">
            <div class="order-info">
              <span class="time">{{ order.createTime }}</span>
              <span>Order No:{{ order.orderNo }}</span>
            </div>
            <div class="store">
              <div :key="item.id" v-for="item in order.items" class="store-info">
                <el-image
                  class="image"
                  :src="item.url"
                  fit="contain"></el-image>
                <div class="_right">
                  <div class="name">{{ item.productName }}</div>
                  <div class="sub-title">{{ item.subTitle }}</div>
                  <div class="price-row">
                    <template v-if="item.productInPromotion==='Y'">
                      <div class="price">
                        ${{ item.productPromotionPrice/100 }}
                      </div>

                      <div class="org-price">
                        ${{ item.productPrice/100 }}
                      </div>
                    </template>
                    <div v-else class="price">
                      ${{ item.productPrice/100 }}
                    </div>

                    <div class="num">
                      x{{ item.amount }}
                    </div>


                  </div>
                </div>


              </div>
            </div>
          </td>
          <td class="td fs22">
            {{ order.createUser }}
          </td>
          <td class="td">
            <el-tag
              class="tag"
              :type="'success'"
              effect="dark">
              {{ order.status | StatusText }}
            </el-tag>
          </td>
          <td class="td align-center">

            <el-button class="btn" @click="toDetail(order)" type="text">Detail</el-button>

          </td>
        </tr>
        </tbody>
      </table>

      <el-empty v-if="orderList.length===0" description="No Data"></el-empty>

      <div style="text-align: center">
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next,sizes"
          :total="total">
        </el-pagination>
      </div>

    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { myOrder } from '@/api/order.js'

export default {
  components: { Breadcrumb },
  data() {
    return {
      activeName: 'ALL',
      pageNum: 1,
      pageSize: 10,
      total: 10,
      orderList: []
    }
  },
  filters: {
    StatusText(value) {
      switch (value) {
        case null:
        case '':
          return 'NONE';
        case 'CANCELED':
          return 'Canceled';
        case 'COMPLETED':
          return 'Completed';
        case 'CONTACTING':
          return 'Processing';
        case 'NOT_CONTACT':
          return 'Contacting';
      }
    }

  },
  async created() {
    await this.queryData()
  },
  methods: {
    async pageChange(page) {
      this.pageNum = page
      await this.queryData()
    },
    async sizeChange(pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      await this.queryData()
    },
    async queryData() {
      const p = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      if (this.activeName !== 'ALL') {
        p.orderStatus = this.activeName;
      }

      const res = await myOrder(p)

      this.orderList = res.data.records.map((item) => {

        item.items = item.items.map((good) => {
          let url = ['']
          if (good.productImageId) {
            url = good.productImageId.split(',');
          }
          good.url = url[0]
          return good
        })

        return item
      })
      this.total = res.data.total
    },
    handleClick(value) {
      this.pageNum = 1;
      console.log(value.name)
      this.queryData()
    },
    toDetail(order) {
      this.$router.push({
        name: 'orderDetail',
        query: {
          id: order.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.bottom-content {
  min-height: 500px;
  background: var(--main-color-bg-gray);
  padding: 20px 75px 75px;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  .image {
    flex: 0 0  220px;
    height: 160px;
    //background: #ccc;
  }

  tr {
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 3px 3px 7px 0 rgba(198, 198, 198, 0.3);
    border-radius: 12px;
    margin-bottom: 10px;
  }

  td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
  }

  thead {

    tr {
      height: 64px;
    }

    th {
      font-size: 22px;
    }
  }

  .tbody {
    display: table-row-group;
  }

  .title1 {
    text-align: left;
    padding-left: 20px;
  }

}

.order-info {
  padding-left: 20px;
  font-size: 22px;
  font-weight: 400;
  color: #767676;
  line-height: 24px;
  margin-bottom: 30px;

  .time {
    margin-right: 50px;
  }
}

.store {
  border-right: 1px solid #ccc;
}

.store-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .image {
    width: 230px;
    height: 154px;
  }


  .price-row {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .num {

      color: #999;
      margin-left: 50px;
      font-size: 30px;
    }
  }

  .name {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
  }


  ._right {
    margin-left: 20px;

  }

  .sub-title {
    font-size: 20px;
  }

  .price {
    font-style: italic;
    font-size: 46px;
    font-family: Poppins-Medium,ArialMT,serif;
    font-weight: 400;
    margin-right: 20px;
    color: #333333;
    line-height: 43px;
  }

  .org-price {
    margin-left: 20px;
    font-family: Poppins-Medium,ArialMT,serif;
    font-size: 36px;
    font-weight: normal;
    color: #868686;
    line-height: 43px;
    position: relative;

    &:before {
      transform: rotate(10deg) translateX(-10%);
      border-radius: 3px;
      border-top: 4px solid var(--main-color-red);
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 50%;
      width: 120%;
      height: 3px;
    }
  }

}

.fs22 {
  font-size: 22px;
}

/deep/ .tab-content {
  padding: 10px 20px 0;

  .el-tabs__header {
    margin-bottom: 8px;
  }

  .el-tabs__active-bar {
    background-color: var(--main-color-red);
  }


  .el-tabs__item {
    height: 30px;
    line-height: 30px;
    font-size: 22px;

    &.is-active {
      color: var(--main-color-red);
    }

    &:hover {
      color: var(--main-color-red);
    }

  }

  .el-tabs__nav-wrap::after {
    display: none;
  }
}

/deep/ .btn {
  font-size: 22px;
}

/deep/ .tag{

  height: unset;
  padding: 5px 10px;
  font-size: 20px;

}

</style>